<template>
  <v-container fluid>
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'users'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1> {{ $t('user.edit.title') }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="userEdit"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-1"
            elevation="2"
            color="red"
            dark
            medium
            @click="dialog = true"
        >
          <v-icon size="28">mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <v-card>
          <v-card-title>
            {{ $t('user.titles.personal_data') }}
          </v-card-title>
          <v-card-text>
            <v-form class="pa-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.first_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('user.first_name') + ' *'"
                                :placeholder="$t('user.first_name')"
                                required
                                :error="errors.first_name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.last_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('user.last_name') + ' *'"
                                :placeholder="$t('user.last_name')"
                                required
                                :error="errors.last_name!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.position"
                                outlined
                                hide-details
                                :label="$t('user.position') + ' *'"
                                :placeholder="$t('user.position')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="form.gender"
                            :label="$t('user.gender.title') + ' *'"
                            :items="gender_select"
                            outlined
                            disabled
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.email"
                                outlined
                                hide-details
                                type="email"
                                :label="$t('user.email') + ' *'"
                                :placeholder="$t('user.email')"
                                required
                                :error="errors.email!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.password"
                                outlined
                                hide-details
                                disabled
                                :label="$t('user.password')"
                                :placeholder="$t('user.password')"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
        </v-card>
        <v-card class="mt-6">
          <v-card-text>
            <RoleAndPermissions v-if="!preloader" :default_role="form.role" :permissions="permissions" :roles="roles" @getRole="getRole" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <v-card class="mb-6">
          <v-card-title>{{ $t('user.titles.settings') }}</v-card-title>
          <v-card-text>
            <v-switch
                v-model="form.email_send"
                :label="$t('user.email_send')"
                color="primary"
            ></v-switch>
            <v-switch
                v-model="form.is_blocked"
                :label="$t('user.is_blocked')"
                color="primary"
            ></v-switch>
            <v-switch
                v-model="form.is_admin"
                :label="$t('user.is_admin')"
                color="primary"
            ></v-switch>
          </v-card-text>
        </v-card>
        <v-card class="mb-6">
          <v-card-title>{{ $t('user.titles.password') }}</v-card-title>
          <v-card-text>
            <PasswordGenerator :status_auto_generate="false" @getPassword="getPassword"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar_error"
        top
        right
        color="red"
    >
      <template v-if="errors.uid!==undefined">
        {{ $t('user.create.errors.user_hast') }}
      </template>
      <template v-else>
        {{ $t('user.create.errors.required_fields') }}
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="500"
      >
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center; word-break: break-word;">
              {{ $t('user.titles.user_delete') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="userDelete">
              {{ $t('user.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialog = false">
              {{ $t('user.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import PasswordGenerator from "@/components/PasswordGenerator";
import RoleAndPermissions from "@/components/Role/RoleAndPermissions";

export default {
  components: {PasswordGenerator, RoleAndPermissions},
  data() {
    return {
      preloader: true,
      snackbar_error: false,
      dialog: false,
      roles: [],
      permissions: [],
      form: {
        identification: '',
        position: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        gender: 'female',
        is_admin: 0,
        is_blocked: 0,
        email_send: 0,
        role: 'manager'
      },
      roles_select: [],
      gender_select: [
        {
          text: this.$t('user.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('user.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('user.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('user.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      errors: {},
    }
  },
  mounted() {
    this.$store.dispatch('userEdit', this.$route.params.id).then(() => {
      const user = this.$store.getters.user

      this.form.id = user.id
      this.form.uid = user.uid
      this.form.identification = user.identification
      this.form.first_name = user.first_name
      this.form.last_name = user.last_name
      this.form.gender = user.gender
      this.form.position = user.position
      this.form.email = user.email
      this.form.is_admin = user.is_admin
      this.form.is_blocked = user.is_blocked
      this.form.role = user.role.name
    })

    this.$store.dispatch('userCreateGet').then(() => {
      this.roles = this.$store.getters.roles
      this.permissions = this.$store.getters.permissions
      this.preloader = false
    })
  },
  methods: {
    getPassword(password) {
      this.form.password = password
    },
    getRole(value) {
      this.form.role = value
    },
    userEdit() {
      this.$store.dispatch('userUpdate', this.form).then(() => {
        this.$router.replace({
          name: 'users'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.snackbar_error = true
            break;
        }
      })
    },
    userDelete() {
      this.$store.dispatch('userDelete', this.form.id).then(() => {
        this.$router.replace({
          name: 'users'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
